<script setup lang="ts">
import { IconInterface } from '@temperworks/types'
import { TemperIcon } from '@temperworks/icons'

import { Badge } from '@temperworks/components'

interface Props {
  size?: string
  label?: string
  loading?: boolean
  icon?: IconInterface
  badge?: number
}
const props = defineProps<Props>()
</script>

<template>
  <div class="button-inner">
    <TemperIcon
      v-if="props.icon && !props.loading"
      :name="props.icon.name"
      :position="props?.icon.position ? props.icon.position : 'left'"
      :size="props?.icon.size ? props.icon.size : size"
      :color="props.icon.color"
    />
    <span
      v-if="!props.loading"
      class="label"
    >
      {{ props.label }}
    </span>
    <span v-else>
      <TemperIcon
        name="loading"
        class="loading"
        position="center"
      />
    </span>
    <Badge
      v-if="props.badge"
      :badge="props.badge"
    />
  </div>
</template>

<style lang="scss" scoped>
@use './Button.scss';
</style>
