<script setup lang="ts">
import { ref, watch } from 'vue'
import { TemperIcon } from '@temperworks/icons'
import { IconInterface } from '@temperworks/types'

interface Props {
  picture?: string
  fallbackIcon: IconInterface
}
const props = defineProps<Partial<Props>>()
const isErrored = ref(false)

/**
 * Verifies the validity of an image source URL by creating an image element and checking for errors.
 * Manages resource cleanup after verification which prevents memory leaks,
 * stops the image from loading, and removes event listeners.
 *
 * @param {string} src - The URL of the image source to verify.
 * @returns {void}
 */
function verifyImageSource(src: string) {
  const img = new Image()
  img.src = src
  img.onload = () => {
    cleanUpImg(img)
  }
  img.onerror = () => {
    isErrored.value = true
    cleanUpImg(img)
  }
}
function cleanUpImg(imgElement: HTMLImageElement) {
  let img: HTMLImageElement | null = imgElement
  img.onload = null
  img.onerror = null
  img.src = ''
  img = null
}

watch(() => props.picture, (newVal) => {
  isErrored.value = false
  verifyImageSource(newVal)
}, { immediate: true })
</script>

<template>
  <div class="base-image">
    <TemperIcon
      v-if="isErrored"
      :name="props.fallbackIcon.name"
      :size="props.fallbackIcon.size"
      :color="props.fallbackIcon.color"
    />
    <img
      v-else
      :src="props.picture"
    >
  </div>
</template>

<style lang="scss" scoped>
@use './BaseImage.scss';
</style>
