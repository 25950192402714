export enum ButtonType {
  blackLarge = 'btn-black large',
  blackMedium = 'btn-black medium',
  blackSmall = 'btn-black small',
  greenLarge = 'btn-green large',
  greenMedium = 'btn-green medium',
  greenSmall = 'btn-green small',
  purpleLarge = 'btn-purple large',
  purpleMedium = 'btn-purple medium',
  purpleSmall = 'btn-purple small',
  purpleLargeDiscrete = 'btn-purple large discrete',
  purpleMediumDiscrete = 'btn-purple medium discrete',
  purpleSmallDiscrete = 'btn-purple small discrete',
  plainSmall = 'btn-plain small',
  plainMedium = 'btn-plain medium',
  plainLarge = 'btn-plain large',
  attentionSmall = 'btn-attention small',
  attentionMedium = 'btn-attention medium',
  attentionLarge = 'btn-attention large',
  outlineSmall = 'btn-outline small',
  outlineMedium = 'btn-outline medium',
  outlineLarge = 'btn-outline large',
  outlineHeader = 'btn-outline header',
  textButtonSmall = 'btn-text small',
  textButtonMedium = 'btn-text medium',
  textButtonLarge = 'btn-text large',
  iconButtonSmall = 'btn-icon small',
  iconButtonMedium = 'btn-icon medium',
  iconButtonLarge = 'btn-icon large',
  cookiebar = 'btn-cookies',
}
