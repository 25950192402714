<script setup lang="ts">
import { Typography } from '@temperworks/components'
import { TypographyType, IconInterface } from '@temperworks/types'
import { TemperIcon } from '@temperworks/icons'

interface Props {
  linkText: string
  disabled?: boolean
  variant: TypographyType
  icon?: IconInterface
}
const props = defineProps<Props>()
const emit = defineEmits(['clicked'])
function clicked() {
  if (!props.disabled) {
    emit('clicked')
  }
}
</script>

<template>
  <Typography
    :variant="props.variant"
    :content="props.linkText"
    class="text-link"
    :class="{ 'text-link--disabled': props.disabled }"
    @clicked="clicked()"
  />
  <TemperIcon
    v-if="props.icon"
    :name="props.icon.name"
    :position="props.icon.position"
    :size="props.icon.size"
    :color="props.icon.color"
  />
</template>

<style lang="scss">
@use './links.scss';
</style>
