<script setup lang="ts">
import { DimmingOverlayVariant } from '@temperworks/types'
const emit = defineEmits(['close'])
function close() {
  emit('close')
}
interface Props {
  variant: DimmingOverlayVariant
}
const props = defineProps<Props>()
</script>

<template>
  <div
    class="overlay"
    :class="props.variant"
    @click="close()"
  />
</template>

<style lang="scss" scoped>
@use './DimmingOverlay.scss';
</style>
