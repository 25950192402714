import { defineStore } from 'pinia'
import { inject, ref } from 'vue'
import {
  AuthStore,
  CompleteProfileSteps,
  CompleteProfileSections
} from '@temperworks/types'

import { ApiWorkerOnboarding } from '../../interfaces/api/worker'
import { useUserStore } from '../user'
import { useCountryStore } from '../country'
import { useAuthStore } from '../auth'
import {
  WorkerProfile,
  WorkExperience,
  JobRatingAndSkills,
  InvoiceDetails,
  WorkerProfileStore,
  Certificate,
  CertificateType
} from '@temperworks/types/src/types/interfaces/Common/stores'

export function setOnboardingProgress(onboardingData: ApiWorkerOnboarding) {
  if (!onboardingData || !onboardingData.sections) return

  return onboardingData.sections
    .filter(section =>
      Object.values(CompleteProfileSections).includes(section.key)
    )
    .map(section => {
      const steps = section.steps.filter(step =>
        Object.values(CompleteProfileSteps).includes(step.key)
      )

      return {
        ...section,
        steps
      }
    })
}

export const useFreeflexerProfileStore: () => WorkerProfileStore = defineStore('worker-profile-store', () => {
  const user = useUserStore()
  const country = useCountryStore()
  const auth: AuthStore = useAuthStore()
  const navigate = inject('navigate') as Function
  const fetchApi = inject('fetchApi') as Function

  const onboardingProgress = ref<ApiWorkerOnboarding | null>({ sections: undefined })

  const profile = ref<WorkerProfile>()
  const workExperience = ref<WorkExperience>()
  const certificates = ref<Certificate[]>()
  const certificateTypes = ref<CertificateType[]>()
  const invoiceDetails = ref<InvoiceDetails>()
  const jobRatingsAndSkills = ref<JobRatingAndSkills[]>([])

  async function getOnboardingProgress() {
    if (!auth.token) return

    try {
      const res = await fetchApi('/api/v4/worker/onboarding', country.backend)
      const data = await res.json()
      onboardingProgress.value.sections = setOnboardingProgress(data)
    } catch (error) {
      console.error('Failed to fetch onboarding progress', error)
    }
  }

  const completeProfilePercentage = computed(() => {
    if (!onboardingProgress?.value?.sections || onboardingProgress.value.sections.length === 0) return 0

    let completedSteps = 0
    let allSteps = 0

    onboardingProgress.value.sections.forEach(section => {
      allSteps = allSteps + section.steps.length
      completedSteps = completedSteps + section.steps.filter(step => step.show_in_screen ? step.completed : true).length
    })

    return 100 * (completedSteps / allSteps)
  })

  async function getProfile() {
    try {
      const res = await fetchApi('/api/v4/worker/profile', country.backend)
      const response = await res.json()
      // This renames the zipcode to zip_code, for it to be picked up correctly by the address component.
      const tmp = response
      if (tmp.home_address) {
        delete Object?.assign(tmp.home_address, { zip_code: response.home_address.zipcode })?.zipcode
      }
      profile.value = tmp
    } catch (error) {
      throw Error('Failed to fetch profile: ' + error)
    }
  }

  async function getWorkExperience() {
    try {
      const res = await fetchApi('/api/v4/worker/profile/work-experiences', country.backend)
      workExperience.value = await res.json()
    } catch (error) {
      throw Error('Failed to fetch work experience: ' + error)
    }
  }

  async function getCertificates() {
    try {
      const res = await fetchApi('/api/v4/worker/profile/certificates', country.backend)
      const data = await res.json()
      certificates.value = data.map(cert => {
        if (Array.isArray(cert.files)) {
          cert.file = cert.files?.[0]
          delete cert.files
        }
        return cert
      })
    } catch (error) {
      throw Error('Failed to fetch certificates: ' + error)
    }
  }

  async function getCertificateTypes() {
    try {
      const res = await fetchApi('/api/v4/worker/profile/certificate-types', country.backend)
      certificateTypes.value = await res.json()
    } catch (error) {
      throw Error('Failed to fetch certificate types: ' + error)
    }
  }

  async function getInvoiceDetails() {
    try {
      const res = await fetchApi('/api/v4/worker/profile/invoice-details', country.backend)
      const response = await res.json()
      const tmp = response
      if (tmp.address) {
        delete Object?.assign(tmp.address, { zip_code: response.address.zipcode })?.zipcode
      }
      invoiceDetails.value = tmp
    } catch (error) {
      throw Error('Failed to fetch invoice details: ' + error)
    }
  }

  async function getJobRatingsAndSkills() {
    try {
      const res = await fetchApi('/api/v4/worker/profile/rating-and-skills-by-job-category', country.backend)
      jobRatingsAndSkills.value = await res.json()
    } catch (error) {
      throw Error('Failed to fetch job ratings and skills: ' + error)
    }
  }

  function redirect() {
    const direction = document.cookie.match(new RegExp('(^| )' + 'redirectUrl' + '=([^;]+)'))
    if (direction) {
      clearCookie()
    }
    if (direction && direction[2].includes('company-identification-number') && direction[2].includes('freeflexer')) {
      return navigate('/freeflexer/onboarding/company-identification-number')
    } else if (direction && direction[2].includes('complete-profile') && direction[2].includes('freeflexer')) {
      return navigate('/freeflexer/complete-profile')
    } else if (user.returnToUrl) {
      const to = user.returnToUrl
      user.returnToUrl = undefined
      return navigateTo(to)
    }
  }

  function clearCookie() {
    document.cookie = ['redirectUrl=', `expires=${new Date(0).toString()}`, 'path=/'].join('; ')
  }

  return {
    onboardingProgress,
    completeProfilePercentage,
    profile,
    workExperience,
    certificates,
    certificateTypes,
    invoiceDetails,
    jobRatingsAndSkills,
    getOnboardingProgress,
    redirect,
    clearCookie,
    getProfile,
    getWorkExperience,
    getCertificates,
    getCertificateTypes,
    getInvoiceDetails,
    getJobRatingsAndSkills
  }
})
