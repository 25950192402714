<script setup lang="ts">
import standard from './standard.vue'
import medium from './medium.vue'
import large from './large.vue'
import { IconColors, IconPosition, IconSizes } from '@temperworks/types'
const props = defineProps<{
  size: IconSizes
  fill: IconColors
  position: IconPosition
}>()
const sizes : { [key: string]: any } = {
  standard,
  medium,
  large,
}

</script>

<template>
  <component
    :is="sizes[props.size]"
    :class="[props.position, props.fill]"
  />
</template>
