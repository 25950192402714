<script setup lang="ts">
import { computed } from 'vue'
import { TypographyType } from '@temperworks/types'

interface Props {
  content?: string
  variant: TypographyType
  class?: string
}
const props = defineProps<Props>()
const emit = defineEmits(['clicked'])
const component = computed(() => {
  if (/[0-9]/.test(props.variant)) {
    return props.variant
  } return 'p'
})
const roles = computed(() => {
  if (/[0-9]/.test(props.variant)) {
    return 'heading'
  } return null
})
const ariaLevel = computed(() => {
  if (/[0-9]/.test(props.variant)) {
    return props.variant.slice(-1)
  } return null
})

function clicked() {
  emit('clicked')
}
</script>

<template>
  <component
    :is="component"
    :class="[props.variant, props.class]"
    :role="roles"
    :aria-level="ariaLevel"
    @click="clicked()"
  >
    <span v-if="props.content">
      {{ props.content }}
    </span>
    <span v-else>
      <slot name="translations" />
    </span>
  </component>
</template>

<style lang="scss" scoped>
@use './Typography.scss';
</style>
