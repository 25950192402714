export default {
  base: () => import("/opt/build/repo/apps/spa/layouts/base.vue").then(m => m.default || m),
  dashboard: () => import("/opt/build/repo/apps/spa/layouts/dashboard.vue").then(m => m.default || m),
  focused: () => import("/opt/build/repo/apps/spa/layouts/focused.vue").then(m => m.default || m),
  "focused-white": () => import("/opt/build/repo/apps/spa/layouts/focusedWhite.vue").then(m => m.default || m),
  "focused-wider": () => import("/opt/build/repo/apps/spa/layouts/focusedWider.vue").then(m => m.default || m),
  "freeflexer-focus": () => import("/opt/build/repo/apps/spa/layouts/freeflexer-focus.vue").then(m => m.default || m),
  "freeflexer-full-width": () => import("/opt/build/repo/apps/spa/layouts/freeflexer-fullWidth.vue").then(m => m.default || m),
  "freeflexer-onboarding": () => import("/opt/build/repo/apps/spa/layouts/freeflexer-onboarding.vue").then(m => m.default || m),
  "full-width": () => import("/opt/build/repo/apps/spa/layouts/fullWidth.vue").then(m => m.default || m),
  "help-page": () => import("/opt/build/repo/apps/spa/layouts/helpPage.vue").then(m => m.default || m),
  informative: () => import("/opt/build/repo/apps/spa/layouts/informative.vue").then(m => m.default || m),
  "login-screen": () => import("/opt/build/repo/apps/spa/layouts/loginScreen.vue").then(m => m.default || m)
}