<script setup lang="ts">
import { BadgeVariants } from '@temperworks/types'
interface Props {
  variant?: BadgeVariants
  badge: number
}
const props = defineProps<Props>()
</script>

<template>
  <span
    class="badge"
    :class="props.variant"
  >
    {{ props.badge }}
  </span>
</template>

<style lang="scss" scoped>
@use './Badge.scss';
</style>
