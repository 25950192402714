import { computed } from 'vue'
import { ValidationFields, ValidationErrors, validationHelperOptions, ValidationField } from '@temperworks/types'
import validators from './validators'
import getValidationPresets from './fieldValidationPresets'

export default {
  install: (app, { t }: { t: Function }) => {
    const presets = computed(() => getValidationPresets(t))

    function validateField(field: ValidationField): string | string[] | void {
      const errors = []
      const { name, value, rules, getAll } = field
      const method = getAll ? 'forEach' : 'some'

      if (!rules) { return }

      rules[method]((rule) => {
        let errorMessage
        if (typeof rule === 'string') {
          const [validator, arg] = rule.split(':')
          const isValid = validator.includes('!')
            ? !validators[validator.replace('!', '')]?.(value, arg)
            : validators[validator]?.(value, arg)
          if (!isValid) {
            errorMessage = t(`validation.${validator}`, { field: name, arg })
          }
        } else if (typeof rule === 'function') {
          const error = rule(value)
          errorMessage = error
        }
        if (errorMessage) { errors.push(errorMessage) }
        return !!errorMessage
      })
      return getAll ? errors : (errors[0] || undefined)
    }

    function validateForm(fields: ValidationFields) {
      const errors: ValidationErrors = {}

      Object.keys(fields).forEach((key) => {
        if (!fields[key]) { return }
        const error = validateField(fields[key])
        if (error) {
          errors[key] = error
        }
      })

      return errors
    }

    function getFieldValidation(key: string, options: validationHelperOptions) {
      if (!presets.value[key]) {
        console.warn(`[InputValidation] No validation entry found for field: ${key}`)
        return
      }
      return (typeof presets.value[key] === 'function')
        ? (presets.value[key] as (arg: any) => ValidationField)(options)
        : { ...presets.value[key], value: options.value }
    }

    app.provide('validateForm', validateForm)
    app.provide('validateField', validateField)
    app.provide('getFieldValidation', getFieldValidation)
  }
}
