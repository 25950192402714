import { defineStore } from 'pinia'
export const useAuthStore = defineStore('auth-store', () => {
  const token = ref<string>('')
  const apiAuth = ref<Object>()
  const authenticated = ref<boolean>(false)
  const isAuthenticated = computed(() => authenticated.value)

  function getToken() {
    const savedToken = localStorage.getItem('userToken')
    const rawToken = useAuth().strategy.token.get()
    if (savedToken) {
      token.value = savedToken.toString()
      authenticated.value = true
    } else if (rawToken) {
      token.value = rawToken.toString()
      localStorage.setItem('userToken', rawToken.toString())
      authenticated.value = true
    }
    apiAuth.value = { Authorization: token.value }
  }
  return { token, isAuthenticated, getToken, apiAuth }
})
