<script setup lang="ts">
import { TemperIcon } from '@temperworks/icons'
import { IconColors, IconSizes, IconInterface } from '@temperworks/types'

export interface BadgeInterface extends IconInterface {
  title: string
}

interface Props {
  badges: BadgeInterface[]
}
const props = defineProps<Props>()
</script>

<template>
  <div
    v-if="props.badges?.length"
    class="badge-holder"
  >
    <span
      v-for="(badge, index) in props.badges"
      :key="index"
      :title="badge.title"
      class="badge"
    >
      <TemperIcon
        :name="badge.name"
        :sizes="IconSizes.standard"
        :position="badge.position"
        :color="badge.color || IconColors.grey700"
      />
    </span>
  </div>
</template>

<style lang="scss" scoped>
@use './BadgeHolder.scss';
</style>
