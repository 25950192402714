import { Countries } from '../../../packages/types/src/types/enums/Common/Countries'

export interface Country {
  id: number;
  country: Countries;
  slug: string;
  locale: string;
  i18nLocale: string;
  currency: string;
  backend: string;
}

export default () => {
  const config = useRuntimeConfig()
  const temperCountries: Country[] = [
    {
      id: 1,
      country: Countries.NL,
      slug: 'nl-nl',
      locale: 'nl_NL',
      i18nLocale: 'nl_NL',
      currency: 'EUR',
      backend: config.public.TEMPER_API_URL
    },
    {
      id: 2,
      country: Countries.NL,
      slug: 'en-nl',
      locale: 'en_EN',
      i18nLocale: 'en_NL',
      currency: 'EUR',
      backend: config.public.TEMPER_API_URL
    },
    {
      id: 3,
      country: Countries.UK,
      slug: 'en-gb',
      locale: 'en_EN',
      i18nLocale: 'en_GB',
      currency: 'GBP',
      backend: config.public.TEMPER_API_URLuk
    },
    {
      id: 4,
      country: Countries.FR,
      slug: 'en-fr',
      locale: 'en_EN',
      i18nLocale: 'en_FR',
      currency: 'EUR',
      backend: config.public.TEMPER_API_URLfr
    },
    {
      id: 5,
      country: Countries.FR,
      slug: 'fr-fr',
      locale: 'fr_FR',
      i18nLocale: 'fr_FR',
      currency: 'EUR',
      backend: config.public.TEMPER_API_URLfr
    },
  ]

  return {
    temperCountries
  }
}
