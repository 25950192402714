<script setup lang="ts">
import large from './large.vue'
import { IconColors, IconPosition, IconSizes } from '@temperworks/types'
const props = defineProps<{
  size: IconSizes
  fill: IconColors
  position: IconPosition
}>()
const sizes : { [key: string]: any } = {
  large,
}

</script>

<template>
  <component
    :is="sizes[props.size]"
    :class="[position]"
  />
</template>
