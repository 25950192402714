import { Auth, ExpiredAuthSessionError } from '#auth/runtime'
import { defineNuxtPlugin, useRuntimeConfig } from '#imports'
import { defu } from 'defu';

// Active schemes
import { Oauth2Scheme } from '#auth/runtime'

export default defineNuxtPlugin({
    name: 'nuxt-alt:auth',
    async setup(nuxtApp) {
        // Options
        const options = {
  "globalMiddleware": false,
  "enableMiddleware": true,
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "redirectStrategy": "storage",
  "routerStrategy": "router",
  "watchLoggedIn": true,
  "redirect": {
    "login": "/login",
    "logout": "/logout",
    "home": "/",
    "callback": "/auth/callback/"
  },
  "pinia": {
    "namespace": "auth"
  },
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/"
    }
  },
  "localStorage": {
    "prefix": "auth."
  },
  "sessionStorage": {
    "prefix": "auth."
  },
  "defaultStrategy": "nl"
}

        // Create a new Auth instance
        const auth = new Auth(nuxtApp, options)

        // Register strategies
        auth.registerStrategy('nl', new Oauth2Scheme(auth, defu(useRuntimeConfig()?.public?.auth?.strategies?.['nl'], {
  "endpoints": {
    "authorization": "https://temper.works/oauth/authorize",
    "token": "https://temper.works/oauth/token",
    "logout": "https://temper.works/logout"
  },
  "token": {
    "property": "access_token",
    "type": "Bearer",
    "maxAge": 1800
  },
  "refreshToken": {
    "property": "refresh_token",
    "maxAge": 2592000
  },
  "responseType": "code",
  "grantType": "authorization_code",
  "redirectUri": "https://web.temper.works/auth/callback",
  "logoutRedirectUri": "/logout",
  "clientId": "12",
  "codeChallengeMethod": "S256",
  "autoLogout": true,
  "name": "nl"
})));
auth.registerStrategy('uk', new Oauth2Scheme(auth, defu(useRuntimeConfig()?.public?.auth?.strategies?.['uk'], {
  "endpoints": {
    "authorization": "https://uk.temper.works/oauth/authorize",
    "token": "https://uk.temper.works/oauth/token",
    "logout": "https://uk.temper.works/logout"
  },
  "token": {
    "property": "access_token",
    "type": "Bearer",
    "maxAge": 1800
  },
  "refreshToken": {
    "property": "refresh_token",
    "maxAge": 2592000
  },
  "responseType": "code",
  "grantType": "authorization_code",
  "redirectUri": "https://web.temper.works/auth/callback",
  "logoutRedirectUri": "/logout",
  "clientId": "1000017",
  "codeChallengeMethod": "S256",
  "autoLogout": true,
  "name": "uk"
})));
auth.registerStrategy('fr', new Oauth2Scheme(auth, defu(useRuntimeConfig()?.public?.auth?.strategies?.['fr'], {
  "endpoints": {
    "authorization": "https://fr.temper.works/oauth/authorize",
    "token": "https://fr.temper.works/oauth/token",
    "logout": "https://fr.temper.works/logout"
  },
  "token": {
    "property": "access_token",
    "type": "Bearer",
    "maxAge": 1800
  },
  "refreshToken": {
    "property": "refresh_token",
    "maxAge": 2592000
  },
  "responseType": "code",
  "grantType": "authorization_code",
  "redirectUri": "https://web.temper.works/auth/callback",
  "logoutRedirectUri": "/logout",
  "clientId": "1000017",
  "codeChallengeMethod": "S256",
  "autoLogout": true,
  "name": "fr"
})))

        nuxtApp.provide('auth', auth)

        return auth.init()
        .catch(error => {
            if (process.client) {
                // Don't console log expired auth session errors. This error is common, and expected to happen.
                // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
                // token. We don't want to log this as an error.
                if (error instanceof ExpiredAuthSessionError) {
                    return
                }

                console.error('[ERROR] [AUTH]', error)
            }
        })
    }
})