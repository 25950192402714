import { createPinia } from 'pinia'
import { useCountryStore } from '../store/country'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const pinia = createPinia()

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('changeCountry', (country:any, text:any) => {
    const countryStore = useCountryStore()
    const config = useRuntimeConfig()
    if (country.country?.value) {
      countryStore.setCountry(country.country.value.country)
      if (country.country.value.country === 'uk') {
        text.setLocale('en_EN')
        countryStore.setBackend(config.public.TEMPER_API_URLuk)
      }
      if (country.country.value.country === 'nl' && country.language?.value.short) {
       window.document.cookie = ['i18n=', `expires=${new Date(0).toString()}`, 'path=/'].join('; ')
        text.setLocale(country.language.value.short)
        countryStore.setBackend(config.TEMPER_API_URL)
       window.document.cookie = [`i18n=${country.language.value.short}`, 'path=/', `expires=${new Date(Date.now() + 86400000).toUTCString()}`].join('; ')
      } else if (country.country.value.country === 'nl') {
        countryStore.setBackend(config.TEMPER_API_URL)
       window.document.cookie = ['i18n=', `expires=${new Date(0).toString()}`, 'path=/'].join('; ')
        text.setLocale('nl_NL')
        countryStore.setCountry('nl')
       window.document.cookie = ['i18n=nl_NL', 'path=/', `expires=${new Date(Date.now() + 86400000).toUTCString()}`].join('; ')
      } else if (country.country.value.country === 'fr' && country.language?.value.short) {
       window.document.cookie = ['i18n=', `expires=${new Date(0).toString()}`, 'path=/'].join('; ')
        text.setLocale(country.language.value.short)
        countryStore.setBackend(config.TEMPER_API_URLfr)
       window.document.cookie = [`i18n=${country.language.value.short}`, 'path=/', `expires=${new Date(Date.now() + 86400000).toUTCString()}`].join('; ')
      } else if (country.country.value.country === 'fr') {
        countryStore.setBackend(config.TEMPER_API_URLfr)
       window.document.cookie = ['i18n=', `expires=${new Date(0).toString()}`, 'path=/'].join('; ')
        text.setLocale('en_EN')
        countryStore.setCountry('fr')
       window.document.cookie = ['i18n=en_EN', 'path=/', `expires=${new Date(Date.now() + 86400000).toUTCString()}`].join('; ')
      }
    } else {
      text.setLocale(country.language.value.short)
     window.document.cookie = [`i18n=${country.language.value.short}`, 'path=/', `expires=${new Date(Date.now() + 86400000).toUTCString()}`].join('; ')
    }
  })
})
