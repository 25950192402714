<script setup lang="ts">
import { ref, onMounted, inject } from 'vue'

import { ButtonType, IconInterface } from '@temperworks/types'

import { ButtonInner } from '@temperworks/components'

interface Props {
  variant: ButtonType
  label?: string
  fullWidth?: boolean
  disabled?: boolean
  loading?: boolean
  icon?: IconInterface
  badge?: number
  url?: string
  target?: string
  preventDefault?: boolean
}

const props = defineProps<Props>()
const navigate: Function = inject('navigate')
const emit = defineEmits(['click'])
const size = ref<string>('large')

async function handleClick() {
  if (props.preventDefault) {
    emit('click', props.url)
    return
  }
  navigate(props.url, props.target)
}

onMounted(() => {
  if (props.variant.includes('Small')) {
    size.value = 'standard'
  }
  if (props.variant.includes('Medium')) {
    size.value = 'medium'
  }
})
</script>

<template>
  <a
    v-if="url"
    :href="url"
    :class="[
      props.variant,
      {'disabled': props.disabled}
    ]"
    @click.prevent="handleClick"
    :disabled="disabled"
  >
    <ButtonInner
      :icon="props.icon"
      :label="props.label"
      :loading="props.loading"
      :badge="props.badge"
      :size="size"
    />
  </a>

  <button
    v-else
    :class="[
      props.variant,
      {'disabled': props.disabled, 'full-width': props.fullWidth}
    ]"
    :aria-disabled="disabled"
    @click.prevent="emit('click')"
  >
    <ButtonInner
      :icon="props.icon"
      :label="props.label"
      :loading="props.loading"
      :badge="props.badge"
      :size="size"
    />
  </button>
</template>

<style lang="scss" scoped>
@use './Button.scss';
</style>
