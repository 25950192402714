<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { TemperIcon } from '@temperworks/icons'
import { Typography, DimmingOverlay, DatePickerInner, vClickOutside  } from '@temperworks/components'
import { TypographyType, IconNames, IconColors, IconPosition, DimmingOverlayVariant, DatePickerVartiant } from '@temperworks/types'
const { t } = useI18n()
const emit = defineEmits(['selected'])

const props = defineProps({
  datePreFilled: {
    type: [String, Array],
    default: undefined
  },
  multipleSelect: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  activeWeek: {
    type: Boolean,
    default: false
  },
  allowPast: {
    type: Boolean,
    default: false
  },
  allowFuture: {
    type: Boolean,
    default: true
  },
  allowReset: {
    type: Boolean,
    default: false
  },
  monthsOnly: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  dateRange: {
    type: Number,
    default: 0
  },
  variant: {
    type: String,
    default: DatePickerVartiant.Default
  },
})

onMounted(() => {
  if (!props.datePreFilled) {
    return
  }
  if (!Array.isArray(props.datePreFilled)) {
    selected.value.push(props.datePreFilled)
  } else {
    selected.value = props.datePreFilled
  }
})

const DateOptions = computed<Intl.DateTimeFormatOptions>(() => ({
  month: 'long',
  weekday: props.monthsOnly ? undefined : 'long',
  day: props.monthsOnly ? undefined : 'numeric',
  year: props.allowPast ? 'numeric' : undefined
}))

const locale = document.cookie.match(RegExp('(?:^|;\\s*)' + 'i18n' + '=([^;]*)'))
const localeSelected = locale ? locale[1] : 'nl_NL'
const picker = ref<boolean>(false)
const selected = ref<string[]>([])

function togglePicker() {
  if (props.disabled) return
  picker.value = !picker.value
}

function closePicker() {
  picker.value = false
}

function setDate(e:string) {
  if (!props.multipleSelect) {
    picker.value = false
  }
  emit('selected', e)
}

const composedlabel = computed(() => {
  if (!props.datePreFilled?.length) {
    return t('controls.selectDate')
  }

  if (Array.isArray(props.datePreFilled)) {
    const dates = props.datePreFilled.map((date) => {
      // format date to dd-mm-yyyy
      return new Intl.DateTimeFormat(
        'en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }
      ).format(new Date(date))
    }).join(' - ')
    return dates
  }

  return new Intl.DateTimeFormat(
    localeSelected.replace(/_/g, '-'), DateOptions.value
  ).format(new Date(props.datePreFilled))
})

</script>

<template>
  <div
    class="datepicker-wrapper"
    :class="['datepicker-wrapper', 
      {'table-filter-date-picker-wrap': props.variant === DatePickerVartiant.TableFilter}
    ]"
    v-click-outside="closePicker"
  >
    <div
      class="datepicker"
      :class="[{
        'errored': props.error,
        'active': picker, 
        'disabled': props.disabled,
        'table-filter-date-picker': props.variant === DatePickerVartiant.TableFilter,
        'value-set': props.datePreFilled?.length
      }]"
        
      @click="togglePicker"
    >
      <TemperIcon
        v-if="props.variant === DatePickerVartiant.TableFilter"
        :name="IconNames.filter"
        :color="IconColors.grey800"
        :position="IconPosition.left"
      />
      <Typography
        :variant="TypographyType.body"
        :content="composedlabel"
      />
      <TemperIcon
        v-if="props.variant !== DatePickerVartiant.TableFilter"
        :name="IconNames.dropdownCaretsR"
        :color="IconColors.grey800"
      />
    </div>
    <DatePickerInner
      v-if="picker"
      :multiple-select="props.multipleSelect"
      :active-week="props.activeWeek"
      :preset-date="props.datePreFilled"
      :allow-past="props.allowPast"
      :allow-future="props.allowFuture"
      :allow-reset="props.allowReset"
      :months-only="props.monthsOnly"
      :disabled="props.disabled"
      :date-range="props.dateRange"
      @selected="setDate($event)"
    />
    <DimmingOverlay
      v-if="picker"
      :variant="DimmingOverlayVariant.bright"
      @close="picker = false"
    />
  </div>
</template>

<style lang="scss" scoped>
@use './DatePicker.scss';
</style>
