export enum IconColors {
  transparent = 'transparent',
  dark = 'dark',
  bright = 'bright',
  green50 = 'green50',
  green100 = 'green100',
  green200 = 'green200',
  green300 = 'green300',
  green400 = 'green400',
  green500 = 'green500',
  green600 = 'green600',
  green700 = 'green700',
  green800 = 'green800',
  green900 = 'green900',
  purple50 = 'purple50',
  purple100 = 'purple100',
  purple200 = 'purple200',
  purple300 = 'purple300',
  purple400 = 'purple400',
  purple500 = 'purple500',
  purple600 = 'purple600',
  purple700 = 'purple700',
  purple800 = 'purple800',
  purple900 = 'purple900',
  grey50 = 'grey50',
  grey100 = 'grey100',
  grey200 = 'grey200',
  grey300 = 'grey300',
  grey400 = 'grey400',
  grey500 = 'grey500',
  grey600 = 'grey600',
  grey700 = 'grey700',
  grey800 = 'grey800',
  grey900 = 'grey900',
  neutralGrey50 = 'neutralGrey50',
  neutralGrey100 = 'neutralGrey100',
  neutralGrey200 = 'neutralGrey200',
  neutralGrey300 = 'neutralGrey300',
  neutralGrey400 = 'neutralGrey400',
  neutralGrey500 = 'neutralGrey500',
  neutralGrey600 = 'neutralGrey600',
  neutralGrey700 = 'neutralGrey700',
  neutralGrey800 = 'neutralGrey800',
  neutralGrey900 = 'neutralGrey900',

  ferrari50 = 'ferrari50',
  ferrari100 = 'ferrari100',
  ferrari200 = 'ferrari200',
  ferrari300 = 'ferrari300',
  ferrari400 = 'ferrari400',
  ferrari500 = 'ferrari500',
  ferrari600 = 'ferrari600',
  ferrari700 = 'ferrari700',
  ferrari800 = 'ferrari800',
  ferrari900 = 'ferrari900',
  bubblegum50 = 'bubblegum50',
  bubblegum100 = 'bubblegum100',
  bubblegum200 = 'bubblegum200',
  bubblegum300 = 'bubblegum300',
  bubblegum400 = 'bubblegum400',
  bubblegum500 = 'bubblegum500',
  bubblegum600 = 'bubblegum600',
  bubblegum700 = 'bubblegum700',
  bubblegum800 = 'bubblegum800',
  bubblegum900 = 'bubblegum900',
  orange50 = 'orange50',
  orange100 = 'orange100',
  orange200 = 'orange200',
  orange300 = 'orange300',
  orange400 = 'orange400',
  orange500 = 'orange500',
  orange600 = 'orange600',
  orange700 = 'orange700',
  orange800 = 'orange800',
  orange900 = 'orange900',
  gold50 = 'gold50',
  gold100 = 'gold100',
  gold200 = 'gold200',
  gold300 = 'gold300',
  gold400 = 'gold400',
  gold500 = 'gold500',
  gold600 = 'gold600',
  gold700 = 'gold700',
  gold800 = 'gold800',
  gold900 = 'gold900'
}

export enum IconNames {
  search = 'search',
  person = 'person',
  calendar = 'calendar',
  clock = 'clock',
  glasses = 'glasses',
  glassesCrossed = 'glassesCrossed',
  timer = 'timer',
  shoppingBag = 'shoppingBag',
  alertOff = 'alertOff',
  alertOn = 'alertOn',
  document = 'document',
  documentAccepted = 'documentAccepted',
  documentDeclined = 'documentDeclined',
  telephone = 'telephone',
  email = 'email',
  profile = 'profile',
  profileSolid = 'profileSolid',
  folder = 'folder',
  camera = 'camera',
  heart = 'heart',
  image = 'image',
  imageEmpty = 'imageEmpty',
  speechBubble = 'speechBubble',
  flag = 'flag',
  sun = 'sun',
  location = 'location',
  notificationOn = 'notificationOn',
  notificationOff = 'notificationOff',
  id = 'id',
  idea = 'idea',
  permit = 'permit',
  passport = 'passport',
  graph = 'graph',
  transport = 'transport',
  send = 'send',
  gift = 'gift',
  lightbulb = 'lightbulb',
  education = 'education',
  vaccination = 'vaccination',
  support = 'support',
  trophy = 'trophy',
  science = 'science',
  diamond = 'diamond',
  square = 'square',
  drawingHand = 'drawingHand',
  laptop = 'laptop',
  foodServing = 'foodServing',
  add = 'add',
  addRound = 'addRound',
  addCompany = 'addCompany',
  addUser = 'addUser',
  arrowDown = 'arrowDown',
  arrowLeft = 'arrowLeft',
  arrowUp = 'arrowUp',
  arrowRight = 'arrowRight',
  bubbleCheck = 'bubbleCheck',
  bubbleCheckFilled = 'bubbleCheckFilled',
  bubbleClear = 'bubbleClear',
  bubbleClearFilled = 'bubbleClearFilled',
  bubbleExclamation = 'bubbleExclamation',
  bubbleExclamationFilled = 'bubbleExclamationFilled',
  bubbleMin = 'bubbleMin',
  bubblePause = 'bubblePause',
  bubblePlay = 'bubblePlay',
  bubblePlus = 'bubblePlus',
  bubbleQuestion = 'bubbleQuestion',
  bubbleQuestionFilled = 'bubbleQuestionFilled',
  cardView = 'cardView',
  caretUp = 'caretUp',
  caretDown = 'caretDown',
  checkmark = 'checkmark',
  chevronDown = 'chevronDown',
  chevronLeft = 'chevronLeft',
  chevronUp = 'chevronUp',
  chevronRight = 'chevronRight',
  close = 'close',
  closePanel = 'closePanel',
  crop = 'crop',
  deleteIcon = 'deleteIcon',
  dividerVertical = 'dividerVertical',
  download = 'download',
  dropdownCaretsC = 'dropdownCaretsC',
  dropdownCaretsR = 'dropdownCaretsR',
  duplicate = 'duplicate',
  edit = 'edit',
  expand = 'expand',
  eyeOff = 'eyeOff',
  eyeOn = 'eyeOn',
  filter = 'filter',
  home = 'home',
  inbox = 'inbox',
  listView = 'listView',
  loading = 'loading',
  locked = 'locked',
  menu = 'menu',
  menuUnread = 'menuUnread',
  minus = 'minus',
  more = 'more',
  newTab = 'newTab',
  options = 'options',
  plus = 'plus',
  print = 'print',
  repeat = 'repeat',
  repeatChecked = 'repeatChecked',
  rotate = 'rotate',
  share = 'share',
  sort = 'sort',
  thumbsDown = 'thumbsDown',
  thumbsUp = 'thumbsUp',
  unlocked = 'unlocked',
  upload = 'upload',
  warning = 'warning',
  warningFilled = 'warningFilled',
  company = 'company',
  shiftTime = 'shiftTime',
  shiftCancellation = 'shiftCancellation',
  cancellationFF = 'cancellationFF',
  substitution = 'substitution',
  negotiation = 'negotiation',
  impersonation = 'impersonation',
  block = 'block',
  checkout = 'checkout',
  flexpool = 'flexpool',
  hourlyRateEuro = 'hourlyRateEuro',
  euroCrossed = 'euroCrossed',
  hourlyRateEuroEdit= 'hourlyRateEuroEdit',
  hourlyRateUK = 'hourlyRateUK',
  poundCrossed = 'poundCrossed',
  hourlyRateUKEdit = 'hourlyRateUKEdit',
  priceMatch = 'priceMatch',
  directPayment = 'directPayment',
  paymentReceived = 'paymentReceived',
  paymentCancelled = 'paymentCancelled',
  sharingTips = 'sharingTips',
  vat = 'vat',
  codeOfConduct = 'codeOfConduct',
  insurance ='insurance',
  attendency = 'attendency',
  punctuality = 'punctuality',
  userPermissions = 'userPermissions',
  flexibleTimes ='flexibleTimes',
  breakRequired = 'breakRequired',
  proBadge = 'proBadge',
  starFullColor = 'starFullColor',
  starHalfColor = 'starHalfColor',
  starEmptyColor = 'starEmptyColor',
  logoTemperMobile = 'logoTemperMobile',
  logoTemper = 'logoTemper',
  android = 'android',
  apple = 'apple',
  facebookColor = 'facebookColor',
  facebookMono = 'facebookMono',
  instagramColor = 'instagramColor',
  instagramMono = 'instagramMono',
  googleColor = 'googleColor',
  googleMono = 'googleMono',
  linkedinColor = 'linkedinColor',
  linkedinMono = 'linkedinMono',
  twitterColor = 'twitterColor',
  twitterMono = 'twitterMono',
  achievementsMedal = 'achievementsMedal',
  achievementsAward = 'achievementsAward',
  achievementsDoItTogether = 'achievementsDoItTogether',
  achievementsCup = 'achievementsCup',
  achievementsPerfect = 'achievementsPerfect',
  clothingCap = 'clothingCap',
  clothingShirt = 'clothingShirt',
  clothingTie = 'clothingTie',
  contactChat = 'contactChat',
  contactHeadset = 'contactHeadset',
  contactMail = 'contactMail',
  contactOpenMail = 'contactOpenMail',
  contactPhone = 'contactPhone',
  contactSpeechBubble = 'contactSpeechBubble',
  contactText = 'contactText',
  documentBlankIllustrative = 'documentBlankIllustrative',
  documentFilledIllustrative = 'documentFilledIllustrative',
  documentPassport = 'documentPassport',
  foodDrinksBeer = 'foodDrinksBeer',
  foodDrinksChampagne = 'foodDrinksChampagne',
  foodDrinksCoffee = 'foodDrinksCoffee',
  foodDrinksIcecream = 'foodDrinksIcecream',
  foodDrinksLemon = 'foodDrinksLemon',
  foodDrinksLemonSlice = 'foodDrinksLemonSlice',
  foodDrinksNoodles = 'foodDrinksNoodles',
  foodDrinksPizza = 'foodDrinksPizza',
  foodDrinksPokebowl = 'foodDrinksPokebowl',
  foodDrinksWine = 'foodDrinksWine',
  hospitalityAssistentChef = 'hospitalityAssistentChef',
  hospitalityBarista = 'hospitalityBarista',
  hospitalityBartending = 'hospitalityBartending',
  hospitalityCatering = 'hospitalityCatering',
  hospitalityChef = 'hospitalityChef',
  hospitalityCleaning = 'hospitalityCleaning',
  hospitalityHosting = 'hospitalityHosting',
  hospitalityHousekeeping = 'hospitalityHousekeeping',
  hospitalityRoomService = 'hospitalityRoomService',
  hospitalityServing = 'hospitalityServing',
  hospitalitySitecrew = 'hospitalitySitecrew',
  hospitalityTraining = 'hospitalityTraining',
  hospitalityWardrobe = 'hospitalityWardrobe',
  jobHoreca = 'jobHoreca',
  jobHospitality = 'jobHospitality',
  jobLogistics = 'jobLogistics',
  jobRetail = 'jobRetail',
  logisticsDelivery = 'logisticsDelivery',
  logisticsMover = 'logisticsMover',
  logisticsOrderpicker = 'logisticsOrderpicker',
  logisticsWarehouseAssistent = 'logisticsWarehouseAssistent',
  moneyBilling = 'moneyBilling',
  moneyCalculator = 'moneyCalculator',
  moneyCash = 'moneyCash',
  moneyCashRegister = 'moneyCashRegister',
  moneyCashStack = 'moneyCashStack',
  moneyPayout = 'moneyPayout',
  moneyPiggyBank = 'moneyPiggyBank',
  moneySmartPricing = 'moneySmartPricing',
  moneyTipping = 'moneyTipping',
  moneyTippingNone = 'moneyTippingNone',
  natureCloud = 'natureCloud',
  natureEarth = 'natureEarth',
  natureMoon = 'natureMoon',
  natureSun = 'natureSun',
  natureTree = 'natureTree',
  officeBooks = 'officeBooks',
  officeCouch = 'officeCouch',
  officePlants = 'officePlants',
  officeSuitcase = 'officeSuitcase',
  officeTemperature = 'officeTemperature',
  othersCactus = 'othersCactus',
  othersCar = 'othersCar',
  othersConstruction = 'othersConstruction',
  othersDisco = 'othersDisco',
  othersFinish = 'othersFinish',
  othersFireplace = 'othersFireplace',
  othersGrowth = 'othersGrowth',
  othersHeart = 'othersHeart',
  othersIdea = 'othersIdea',
  othersKite = 'othersKite',
  othersLightning = 'othersLightning',
  othersMovie = 'othersMovie',
  othersNavigation = 'othersNavigation',
  othersObservatory = 'othersObservatory',
  othersOkSign = 'othersOkSign',
  othersPaperplane = 'othersPaperplane',
  othersSettings = 'othersSettings',
  othersSmileyHappy = 'othersSmileyHappy',
  othersSmileySad = 'othersSmileySad',
  othersStar = 'othersStar',
  partyBalloons = 'partyBalloons',
  partyCelebrations = 'partyCelebrations',
  partyHat = 'partyHat',
  partyPopper = 'partyPopper',
  platformAgreement = 'platformAgreement',
  platformCancellationPolicy = 'platformCancellationPolicy',
  platformClient = 'platformClient',
  platformCompany = 'platformCompany',
  platformDispute = 'platformDispute',
  platformFlexpool = 'platformFlexpool',
  platformFreeflexer = 'platformFreeflexer',
  platformFreeflexers = 'platformFreeflexers',
  platformInsurance = 'platformInsurance',
  platformLanguage = 'platformLanguage',
  platformLocation = 'platformLocation',
  platformLocked = 'platformLocked',
  platformNegotiation = 'platformNegotiation',
  platformNotify = 'platformNotify',
  platformProfile = 'platformProfile',
  platformRemove = 'platformRemove',
  platformSearch = 'platformSearch',
  platformSettings = 'platformSettings',
  platformSubstitution = 'platformSubstitution',
  platformSwitchLanguage = 'platformSwitchLanguage',
  platformTeam = 'platformTeam',
  promotionProductPromotion = 'promotionProductPromotion',
  promotionStreetClerk = 'promotionStreetClerk',
  retailCheckout = 'retailCheckout',
  retailCustomerService = 'retailCustomerService',
  retailSales = 'retailSales',
  screenDashboard1 = 'screenDashboard1',
  screenDashboard2 = 'screenDashboard2',
  screenDesktop = 'screenDesktop',
  screenLaptop = 'screenLaptop',
  screenMobile = 'screenMobile',
  statisticsGraph1 = 'statisticsGraph1',
  statisticsGraph2 = 'statisticsGraph2',
  statisticsPieChart = 'statisticsPieChart',
  temperBeABoss = 'temperBeABoss',
  temperFoosball = 'temperFoosball',
  temperGetTogether = 'temperGetTogether',
  temperMakeMagic = 'temperMakeMagic',
  temperTableTennis = 'temperTableTennis',
  temperUnicorn = 'temperUnicorn',
  temperValueWeBattleForTheBest = 'temperValueWeBattleForTheBest',
  temperValueWeDoItForThem = 'temperValueWeDoItForThem',
  temperValueWeMoveToImprove= 'temperValueWeMoveToImprove',
  temperValueWeOwnIt = 'temperValueWeOwnIt',
  temperValueWeShareToSucceed = 'temperValueWeShareToSucceed',
  timeCalendar = 'timeCalendar',
  timeClock = 'timeClock',
  timeDate = 'timeDate',
  timeFlexible = 'timeFlexible',
  timeHourglass = 'timeHourglass',
  timeRecurring = 'timeRecurring',
  timeStopwatch = 'timeStopwatch',
  financeReceipt = 'financeReceipt',
  bookManual = 'bookManual',
  bookmarkFlag = 'bookmarkFlag'
}

export enum IconPosition {
  left = 'left',
  center = 'center',
  right = 'right'
}

export enum IconSizes {
  small = 'small',
  standard = 'standard',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge'
}
