<template>
  <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1092_18187)">
      <rect width="104" height="104" rx="52" fill="#EEEBF4"/>
      <path
        d="M52.1957 16C39.7017 16 29.5734 26.0736 29.5734 38.5C29.5734 50.9264 39.7017 61 52.1957 61C64.6896 61 74.8179 50.9264 74.8179 38.5C74.8179 26.0736 64.6896 16 52.1957 16ZM25.6142 70C20.3056 70 16 74.2779 16 79.56L16 81.25C16 89.7 20.3925 96.0256 27.1305 100.105C33.7564 104.117 42.6628 106 52.1957 106C61.7285 106 70.6349 104.117 77.2608 100.105C83.9988 96.0256 88.3913 89.7 88.3913 81.25L88.3913 79.5596C88.3912 74.2776 84.0857 70 78.7773 70H25.6142Z"
        fill="#BCB8C3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1092_18187">
        <rect width="104" height="104" rx="52" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
