<script setup lang="ts">
import { ref, computed, watchEffect } from 'vue'
import { Typography, Button, TextLink } from '@temperworks/components'
import { useI18n } from 'vue-i18n'
import { isWithinInterval } from 'date-fns'

import {
  TypographyType,
  IconColors,
  IconNames,
  IconSizes,
  ButtonType,
  DatePickerVartiant
} from '@temperworks/types'

const { t } = useI18n()
interface Props {
  multipleSelect?: boolean
  activeWeek: boolean
  presetDate?: string | string[],
  allowPast?: boolean,
  allowFuture?: boolean,
  allowReset?: boolean,
  monthsOnly?: boolean,
  showSelectedCount?: boolean,
  dateRange?: number
  variant?: DatePickerVartiant
}
const props = defineProps<Props>()
const firstDayOfWeek = ref<any>()
const firstDay = ref<any>()
const lastDay = ref<any>()
const lastDayOfWeek = ref<any>()
const backButton = {
  name: IconNames.arrowLeft,
  color: IconColors.purple500,
  size: IconSizes.standard
}
const nextButton = {
  name: IconNames.arrowRight,
  color: IconColors.purple500,
  size: IconSizes.standard
}

const emit = defineEmits(['selected'])
const monthView = ref<boolean>(false)
const inCurrentWeek = ref<boolean>(false)
const selected = ref<string[]>([])
const timeOptions = { year: 'numeric' as const, month: 'numeric' as const, day: 'numeric' as const }
const today = new Date().toLocaleDateString('fr-CA', timeOptions)
const selectedMonth = ref<number>(new Date().getMonth())
const thisMonth = ref<number>(new Date().getMonth())
const year = ref<number>(new Date().getFullYear())
const months = [
  t('months.jan'),
  t('months.feb'),
  t('months.mrt'),
  t('months.apr'),
  t('months.may'),
  t('months.jun'),
  t('months.jul'),
  t('months.aug'),
  t('months.sep'),
  t('months.oct'),
  t('months.nov'),
  t('months.dec')
]
const daysWritten = [
  t('days.mon'),
  t('days.tue'),
  t('days.wed'),
  t('days.thu'),
  t('days.fri'),
  t('days.sat'),
  t('days.sun')
]

watchEffect(() => {
  if (!props.presetDate || !props.presetDate.length) {
    return
  }

  if (Array.isArray(props.presetDate)) {
    const date = props.presetDate[props.presetDate.length - 1]
    inThisWeek(date)
    selected.value = props.presetDate

    // Set month and year
    const selectedDate = new Date(date)
    selectedMonth.value = selectedDate.getMonth()
    year.value = selectedDate.getFullYear()
    return
  }

  inThisWeek(props.presetDate)
  selected.value.push(props.presetDate)

  // Set month and year
  const selectedDate = new Date(props.presetDate)
  selectedMonth.value = selectedDate.getMonth()
  year.value = selectedDate.getFullYear()
})

const selectedWeekIndex = computed(() => {
  if (!props.activeWeek || !selected?.value?.[0]) return -1
  return weeks.value.findIndex((week) => week.includes(selected.value[0]))
})

const isFutureDate = (date: Date): boolean => {
  const today = new Date()
  return date > today
}

const isPastDate = (date: Date): boolean => {
  const today = new Date()
  return date < today
}

const getAllDaysInMonth = (month, year) => {
  const firstDayOfMonth = new Date(year, month - 1, 1).getDay()
  const daysInMonth = new Date(year, month, 0).getDate()
  const days: Date[] = []

  // Previous month's days
  for (let i = firstDayOfMonth; i > 0; i--) {
    days.push(new Date(year, month - 1, -i + 1))
  }

  // Current month's days
  for (let i = 1; i <= daysInMonth; i++) {
    days.push(new Date(year, month - 1, i))
  }

  // Next month's days
  const remainingDays = 42 - days.length
  for (let i = 1; i <= remainingDays; i++) {
    days.push(new Date(year, month, i))
  }

  return days
}

const days = computed(() => {
  const allDatesInCurrentMonth = getAllDaysInMonth(selectedMonth.value + 1, year.value)
  return allDatesInCurrentMonth
    .slice(0, 6 * 7)
    .map(x => x.toLocaleDateString('fr-CA', timeOptions))
})

const weeks = computed(() => {
  const weeks: string[][] = []
  for (let i = 0; i < days.value.length; i += 7) {
    weeks.push(days.value.slice(i, i + 7))
  }
  return weeks
})

const previousMonth = computed(() => {
  return new Date(year.value, selectedMonth.value, 3).toLocaleDateString(
    'fr-CA', { year: 'numeric', month: 'numeric' }
  )
})
const currentMonth = computed(() => {
  return months[selectedMonth.value] + ' ' + year.value
})

function selectedDate(date: string) {
  if (!props.multipleSelect) {
    selected.value = []
  }

  if ((isFutureDate(new Date(date)) && !props.allowFuture) || (isPastDate(new Date(date)) && !props.allowPast)) {
    return
  }

  if (new Date(date) >= new Date(today) || props.allowPast) {
    const index = selected.value.indexOf(date)
    if (index > -1) {
      // If the date is already selected, unselect it
      selected.value.splice(index, 1)
    } else {
      // If the date is not selected, select it
      selected.value.push(date)

      if (selected.value.length > 2) {
        selected.value.shift()
      }

      // sort dates
      selected.value.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    }
    inThisWeek(date)
    emit('selected', selected.value)
  }
}

function selectMonth(month: number) {
  selectedMonth.value = month
  monthView.value = false
  if (props.monthsOnly) {
    const selectedDate = new Date(year.value, selectedMonth.value)
    const formattedDate = selectedDate.toLocaleDateString('fr-CA', timeOptions)
    emit('selected', formattedDate)
  }
}

function changeMonth(e: number) {
  selectedMonth.value = selectedMonth.value + e
  if (selectedMonth.value === 12) {
    year.value++
    selectedMonth.value = 0
  } else if (selectedMonth.value === -1) {
    year.value--
    selectedMonth.value = 11
  }
}

function inThisWeek(date: string) {
  const todayObj = new Date(date)
  const todayDate = todayObj.getDate() + 1
  const todayDay = todayObj.getDay()
  firstDayOfWeek.value = new Date(todayObj.setDate(todayDate - todayDay))
  firstDay.value = firstDayOfWeek.value.toLocaleDateString('fr-CA', timeOptions)
  lastDayOfWeek.value = new Date(firstDayOfWeek.value)
  lastDayOfWeek.value.setDate(lastDayOfWeek.value.getDate() + 6)
  lastDay.value = lastDayOfWeek.value.toLocaleDateString('fr-CA', timeOptions)

  if (
    date >= firstDayOfWeek.value.toLocaleDateString('fr-CA', timeOptions) &&
    date <= lastDayOfWeek.value.toLocaleDateString('fr-CA', timeOptions)
  ) {
    inCurrentWeek.value = true
  }
}

function dayIsWithinRange(dateString: string): boolean {
  if (!props.dateRange) {
    return true
  }

  const inputDate = new Date(dateString)
  const today = new Date()
  const finalDate = new Date(today)
  finalDate.setDate(finalDate.getDate() + props.dateRange)

  return inputDate >= today && inputDate <= finalDate
}

function isDayWithinSelectedRange(day: string): boolean {
  if (!props.presetDate && !Array.isArray(props.presetDate)) {
    return false
  }
  const [start, end] = props.presetDate
  if (!start || !end) {
    return false
  }

  return isWithinInterval(new Date(day), { start: new Date(start), end: new Date(end) })
}

function monthIsWithinRange(month: number): boolean {
  if (!props.dateRange) {
    return true
  }

  const today = new Date()
  const finalDate = new Date()
  finalDate.setDate(today.getDate() + props.dateRange)
  const startMonth = today.getMonth() + 1
  const endMonth = finalDate.getMonth() + 1

  return startMonth < endMonth
    ? month < endMonth
    : month > startMonth || month < endMonth
}

function yearIsWithinRange(year: number): boolean {
  if (!props.dateRange) {
    return true
  }

  const today = new Date()
  const finalDate = new Date()
  finalDate.setDate(today.getDate() + props.dateRange)
  const nextYearStart = new Date(`${year}-01-01`)
  const nextYearEnd = new Date(`${year}-12-31`)

  return (nextYearStart <= finalDate) && (today <= nextYearEnd)
}

const onReset = () => {
  if (!props.allowReset) {
    return
  }
  if (props.multipleSelect) {
    selected.value = []
    emit('selected', [])
    return
  }
  emit('selected', undefined)
}

</script>

<template>
  <div
    class="datepicker-selector"
    :class="{ month: monthView || props.monthsOnly }"
  >
    <div class="datepicker-selector-header">
      <Button
        v-if="monthView || props.monthsOnly"
        :variant="ButtonType.purpleSmallDiscrete"
        label=""
        :disabled="!allowPast && (year === new Date().getFullYear())"
        :icon="backButton"
        @click="year--"
      />
      <div
        class="month-selector"
        @click="monthView = true"
      >
        <Typography
          :variant="TypographyType.bodyMedium"
          :content="monthView ? year.toString() : currentMonth"
        />
      </div>
      <Button
        v-if="monthView || props.monthsOnly"
        :variant="ButtonType.purpleSmallDiscrete"
        label=""
        :icon="nextButton"
        :disabled="!yearIsWithinRange(year + 1)"
        @click="year++"
      />
      <div
        v-if="!monthView && !props.monthsOnly"
        class="buttons"
      >
        <Button
          :variant="ButtonType.purpleSmallDiscrete"
          label=""
          :disabled="!
            props.allowPast &&
            year <= new Date().getFullYear() &&
            selectedMonth < new Date().getMonth() + 1
            "
          :icon="backButton"
          @click="changeMonth(-1)"
        />
        <Button
          :variant="ButtonType.purpleSmallDiscrete"
          :disabled="!monthIsWithinRange(selectedMonth + 1) || (!props.allowFuture && isFutureDate(new Date(year, selectedMonth + 1)))"
          label=""
          :icon="nextButton"
          @click="changeMonth(1)"
        />
      </div>
    </div>

    <div
      v-if="!monthView && !props.monthsOnly"
      class="calendar"
    >
      <div class="week">
        <div
          v-for="item, index in daysWritten"
          :key="`calendar-day-label-${index}`"
          class="days"
        >
          {{ item.substring(0, 2) }}
        </div>
      </div>
      <div
        v-for="(week, index) in weeks"
        :key="`calendar-week-${index}`"
        :class="{ 'week': true, 'activeWeek': selectedWeekIndex === index, 'highlight': activeWeek }"
      >
        <div
          v-for="day in week"
          :key="`calendar-day-${day}`"
          :class="[
            { 'first': firstDay === day && props.activeWeek && inCurrentWeek },
            { 'last': lastDay === day && props.activeWeek && inCurrentWeek },
            { 'active': day.includes(today) },
            { 'selected': props.multipleSelect ? selected.includes(day) : selected[0] === day },
            { 'history': !day.includes(previousMonth) },
            { 'history': new Date(day) < new Date(today) && !props.allowPast },
            { 'history': !dayIsWithinRange(day) },
            { 'within-selected-range': isDayWithinSelectedRange(day) },
            { 'range-start': selected[0] === day && selected.length === 2 },
            { 'range-end': selected[1] === day && selected.length === 2 },
            { 'disabled': !props.allowFuture && isFutureDate(new Date(day)) }
          ]"
          class="day"
          @click="selectedDate(day)"
        >
          <span :class="{ 'active-in-week': day.includes(today) && props.activeWeek }">
            {{ day.slice(-2) }}
          </span>
        </div>
      </div>
      <div
        v-if="props.multipleSelect && selected.length > 1 && !monthView"
        class="datepicker-footer"
      >
        <Typography
          v-if="showSelectedCount"
          :variant="TypographyType.footnote"
          :content="selected.length + ' days selected'"
        />
        <TextLink
          :variant="TypographyType.footnote"
          :link-text="'Undo'"
          @clicked="selected = []"
        />
      </div>
    </div>

    <div
      v-else
      class="calendar"
    >
      <div
        v-for="month, index in months"
        :key="`calendar-month-label-${index}`"
        class="calendar month"
        :class="{
          'active': index === thisMonth,
          'disabled': !monthIsWithinRange(index) || !allowPast && (index < new Date().getMonth())
        }"
        @click.stop="selectMonth(index)"
      >
        {{ month.substring(0, 3) }}
      </div>
    </div>

    <div
      class="reset-section"
      v-if="props.allowReset"
    >
      <button @click="onReset">
        {{ t('components.datepicker.resetdate') }}
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use './DatePicker.scss';
</style>
