<script setup lang="ts">
import { IconColors } from '@temperworks/types'
interface Props {
  fill: IconColors
}
const props = defineProps<Props>()
</script>

<template>
  <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
  >
    <path
      d="M3 2C3 0.895431 3.89543 0 5 0H9C10.1046 0 11 0.89543 11 2H11.5C12.3284 2 13 2.67157 13 3.5V4.5C13 5.751 12.0806 6.78705 10.8813 6.97098C10.4867 8.55371 9.14925 9.76338 7.50003 9.96905V11H9C10.1046 11 11 11.8954 11 13V13.5035C11 13.7796 10.7761 14.0035 10.5 14.0035H3.5C3.22386 14.0035 3 13.7796 3 13.5035V13C3 11.8954 3.89543 11 5 11H6.50003V9.96906C4.85083 9.76342 3.51341 8.55379 3.1187 6.97111C1.91915 6.78758 0.99707 5.75188 0.99707 4.5V3.5C0.99707 2.67157 1.66864 2 2.49707 2L3 2ZM10 2C10 1.44772 9.55228 1 9 1H5C4.44772 1 4 1.44772 4 2V6C4 7.65685 5.34315 9 7 9C8.63359 9 9.96222 7.69431 9.99921 6.0696L10 2ZM11 5.91441C11.5826 5.70826 12 5.15268 12 4.5V3.5C12 3.22386 11.7761 3 11.5 3H11V5.91441ZM3 3H2.49707C2.22093 3 1.99707 3.22386 1.99707 3.5V4.5C1.99707 5.15262 2.41604 5.70886 3 5.9148V3ZM4 13V13.0035H10V13C10 12.4477 9.55228 12 9 12H5C4.44772 12 4 12.4477 4 13Z"
      :fill="props.fill"
    />
  </svg>
</template>
