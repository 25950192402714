/**
 * gets locale from cookie, validates and returns locale
 * if locale is not valid, fallback to "en-EN"
 */
function getLocaleFromCookie(): string {
  const locale = window.document.cookie.match(RegExp('(?:^|;\\s*)' + 'i18n' + '=([^;]*)'))?.[1]?.replace(/_/g, '-')
  try {
    const validatedLocale = Intl.getCanonicalLocales(locale)?.[0]
    return validatedLocale ? validatedLocale : "en-EN"
  } catch (error) {
    return "en-EN"
  }
}

function country(): string {
  return window.document.cookie.match(RegExp('(?:^|;\\s*)' + 'country' + '=([^;]*)'))?.[1]?.replace(/_/g, '-')
  ?? 'nl'
}

// Depending on the country we have to use a specific ISO standard for dates
// it's possible the locale we need to dispaly doesn't match the actual locale, so we need to parse it
function getDateLocale(): string {
  const dateLocales = {
    'uk': 'en-EN',
    'fr': 'fr-FR',
    'nl': 'nl-NL'
  }

  return dateLocales[country()]
}
/* eslint-disable no-param-reassign */
export default {
  install: (app) => {
    const locale = getLocaleFromCookie()
    function readableCurrency(input: number) {
      // English and French should both be formatted according to the 'fr-FR' standard
      if (country() === 'fr') {
        return new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR',
        }).format(input).replace(/\s/g, '')
      }

      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: country() === 'uk' ? 'GBP' : 'EUR',
      }).format(input)
    }

    function readableDate(dateTime: string, options:any) {
      return new Intl.DateTimeFormat(
        getDateLocale(), options
      ).format(new Date(dateTime))
    }

    function readableTime(time: string, options:any) {
      return new Intl.DateTimeFormat(
        getDateLocale(), { timeStyle: options }
      ).format(new Date(time))
    }

    app.config.globalProperties.$readableCurrency = readableCurrency
    app.config.globalProperties.$readableDate = readableDate
    app.config.globalProperties.$readableTime = readableTime
    app.provide('readableCurrency', readableCurrency)
    app.provide('readableDate', readableDate)
    app.provide('readableTime', readableTime)
  }
}
