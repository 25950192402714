<script setup lang="ts">
import { TemperIcon } from '@temperworks/icons'
import { Typography } from '@temperworks/components'
import {
  IconInterface,
  TypographyType,
  IconNames,
  IconSizes,
  IconColors,
  HelpCardVariant,
  ImageTypes,
  Mood
} from '@temperworks/types'

interface Props {
  title: string
  icon?: IconInterface
  openOption?: boolean
  open?: boolean
  moodOption?: Mood
  image?: ImageTypes
  variant?: HelpCardVariant
  closeOption: boolean
}
const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  openOption: false,
  open: true,
  image: null,
  variant: HelpCardVariant.default,
  moodOption: Mood.none,
  closeOption: true
})

const emit = defineEmits(['open', 'close'])
function clickToOpen() {
  if (props.openOption) {
    emit('open')
  }
}
function close() {
  emit('close')
}

</script>

<template>
  <div
    class="help-card"
    :class="{ 'with-image': props.variant?.includes('image')}"
  >
    <div
      v-if="props.variant?.includes('image')"
      class="image"
      :class="props.moodOption"
    >
      <div
        v-if="props.image"
      >
        <picture>
          <source
            media="max-width:992px"
            :srcset="props.image.mobile"
          >
          <img :src="props.image.desktop">
        </picture>
      </div>
    </div>
    <div class="help-card-content">
      <div class="help-card-header">
        <div class="help-card-title">
          <TemperIcon
            v-if="props.icon"
            :name="props.icon.name"
            :color="props.icon.color"
            :position="props.icon.position"
            :size="props.icon.size"
          />
          <Typography
            :variant="TypographyType.bodySmallMedium"
            :content="props.title"
          />
        </div>
        <div
          v-if="props.openOption"
          class="help-card-open"
          @click="clickToOpen"
        >
          <TemperIcon
            :name="props.open ? IconNames.minus : IconNames.plus"
            :color="IconColors.purple500"
            :size="IconSizes.standard"
          />
        </div>
        <div
          v-if="props.closeOption && !props.openOption"
          class="close"
          @click="close"
        >
          <TemperIcon
            :name="IconNames.close"
            :color="IconColors.purple500"
            :size="IconSizes.standard"
          />
        </div>
      </div>
      <slot name="help-card-content" />
      <div
        v-if="$slots.greyed"
        class="greyed-area"
      >
        <slot name="greyed" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use './HelpCard.scss';
</style>
