import { ValidatorMap } from '@temperworks/types'

const validateRequiredByType = {
  number: (value: number) => !!value || value === 0,
  string: (value: string) => !!value && value.length > 0,
  array: (value: Array<any>) => !!value && value.length > 0,
  object: (value: Record<any, any>) => {
    if (!value) return false
    else if (value instanceof FormData) return [...value].length
    else if (value instanceof File) return value.size
    else return Object.keys(value).length
  },
  boolean: () => true,
  undefined: () => false,
}

const validators: ValidatorMap = {
  required: (value: any) => {
    return validateRequiredByType[(Array.isArray(value) ? 'array' : typeof value)](value)
  },
  min: (value: string | Array<any>, len: number) => value?.length >= len,
  max: (value: string | Array<any>, len: number) => value.length <= len,
  email: (value: string) => /^[a-zA-Z0-9._+-]{3,}@[a-zA-Z0-9.-]{3,}\.[a-zA-Z]{2,}$/.test(value),
  hasSymbols: (value: string) => /.*["#$%&'()*+,-./!:;<=>?@[\]^_`{|}~]+.*/.test(value),
  hasDigits: (value: string) => /.*\d+.*/.test(value),
  includes: (value: string, arg: string) => value.includes(arg),
  isTrue: (value: string, arg: string) => String(value) === 'true' || arg === 'true',
  noFutureDate: (value: string) => {
    if (!value) return true
    const date = new Date(value)
    const today = new Date()
    return date <= today
  },
  noSameDate: (value: string, arg: string) => {
    if (!value) return true
    const date = new Date(value)
    const compareDate = new Date(arg)
    return date.getTime() !== compareDate.getTime()
  },
  url: (url: string | null) => {
    if (!url) return true
    // Matches URLs starting with 'http' or 'https', ensuring a maximum length of 255 characters
    const urlPattern = /^(https?:\/\/[^\s]{1,255})$/
    return urlPattern.test(url)
  }
}

export default validators
